'use client';

import { ChevronDown, ChevronsUpDown, X } from 'lucide-react';
import { useDebounce } from '@uidotdev/usehooks';
import classNames from 'classnames';
import { CommandLoading } from 'cmdk';
import { useState, MouseEventHandler, useCallback } from 'react';
import { ComboboxOption } from './combobox-shared';
import { useRemote } from '@spikemark/shared-hooks';
import { Button } from './button';
import { ComboboxOptions, findOptionByValue, getOptionLabel } from './combobox-shared';
import { Command, CommandEmpty, CommandInput, CommandList } from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { cn } from '../../utils/cn';

export type ComboboxAsyncProps<TDatum = never> = {
  fetcher: (search: string) => Promise<ComboboxOption<TDatum>[]>;
  placeholder: string;
  value?: string;
  formatLabel?: (value: string, items: ComboboxOption<TDatum>[] | null) => string;
  onChange?: (value: string, item?: TDatum) => void;
  className?: string;
  filterPlaceholder?: string;
  emptyMessage: string;
  testIdKey?: string;
  disabled?: boolean;
  suppressDelete?: boolean;
  variant?: 'default' | 'dark-flat';
};

export function useDebouncedSearchQuery() {
  const [keywords, setKeywords] = useState('');
  const debouncedKeywords = useDebounce(keywords, 500);
  return {
    keywords,
    debouncedKeywords,
    setKeywords,
  };
}

export function useSearchController<T>(fetcher: (search: string) => Promise<T[]>) {
  const { keywords, setKeywords, debouncedKeywords } = useDebouncedSearchQuery();
  const { data, isFetching, error } = useRemote<T[], string>(fetcher, {
    params: debouncedKeywords,
    skip: !debouncedKeywords,
  });
  return {
    data,
    isFetching,
    error,
    keywords,
    setKeywords,
  };
}

export function ComboboxAsync<TDatum = never>({
  fetcher,
  placeholder,
  onChange,
  className,
  value,
  formatLabel,
  filterPlaceholder = placeholder,
  emptyMessage,
  testIdKey,
  disabled,
  suppressDelete,
  variant = 'default',
}: ComboboxAsyncProps<TDatum>) {
  const [open, setOpen] = useState(false);
  const { data: options, isFetching, keywords, setKeywords } = useSearchController(fetcher);
  const activeOption = options && value ? findOptionByValue(options, value) : null;
  const handleDelete = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation();
      onChange?.('');
    },
    [onChange]
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant={variant == 'dark-flat' ? 'ghost' : 'outline'}
          role="combobox"
          aria-expanded={open}
          className={cn(
            className,
            'px-4 justify-between bg-white',
            value ? 'text-zinc-800' : 'text-zinc-400',
            {
              'h-10 cursor-default rounded-lg px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50 hover:border-zinc-400 active:border-zinc-400 text-primary border-none shadow-none bg-tertiary [&>svg]:bg-primary [&>svg]:text-white w-56':
                variant === 'dark-flat',
            }
          )}
          data-testid={`${testIdKey}-combobox-trigger`}
        >
          <span className="w-full truncate text-left">
            {activeOption
              ? getOptionLabel(activeOption)
              : value
                ? formatLabel?.(value, options) ?? value
                : placeholder}
          </span>
          {value && !suppressDelete && (
            <X
              size={16}
              className="ml-2 shrink-0 opacity-70 hover:opacity-100"
              onClick={handleDelete}
            />
          )}
          {variant === 'dark-flat' ? (
            <ChevronDown className="ml-2 h-6 w-6 shrink-0" />
          ) : (
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        portalled={false}
        className={classNames(className, 'min-w-[var(--radix-popper-anchor-width)]')}
      >
        <Command shouldFilter={false}>
          <CommandInput
            placeholder={filterPlaceholder}
            value={keywords}
            onValueChange={setKeywords}
            data-testid={`${testIdKey}-combobox-search`}
          />
          <CommandList>
            {isFetching ? (
              <CommandLoading />
            ) : options ? (
              <ComboboxOptions<TDatum>
                options={keywords ? options : []}
                value={value}
                onChange={onChange}
                onClose={() => setOpen(false)}
              />
            ) : (
              <CommandEmpty>{emptyMessage}</CommandEmpty>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
